
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.product-assemblytype {
  .custom-radio {
    input:not(:checked) + .custom-control-label {
      color: $gray-700;
    }

    @include media-breakpoint-up($desktop-breakpoint) {
      &:hover {
        input:not(:checked) + .custom-control-label {
          color: $gray-700;

          &::after {
            background-image: escape-svg(str-replace($custom-radio-indicator-icon-checked, "fill='#000'", "fill='rgba(0, 0, 0, 0.54)'"));
          }
        }
      }
    }
  }
}
