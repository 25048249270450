
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

@import '~vue-it-bigger/dist/vue-it-bigger.min.css';

// mobile
$product-description-images-carousel-gap: 0 !default;
$product-description-images-navigation-gap: $spacer !default;
$product-description-images-navigation-slide-gap: $spacer * 0.75 !default;
$product-description-images-navigation-slide-img-gap: $spacer * 0.5 !default;
$product-description-images-navigation-slide-img-border: 2px solid transparent !default;
$product-description-images-navigation-slide-current-img-border-color: $primary !default;

$product-description-images-img-fallback-size: 80% !default;
$product-description-images-img-fallback-color: $imgfallback !default;

// desktop
$product-breakpoint: $desktop-breakpoint !default;

$product-desktop-description-images-carousel-gap: $spacer !default;
$product-desktop-description-images-navigation-gap: $product-description-images-navigation-gap !default;
$product-desktop-description-images-navigation-slide-gap: $product-description-images-navigation-slide-gap !default;
$product-desktop-description-images-navigation-slide-img-gap: $product-description-images-navigation-slide-img-gap !default;
$product-desktop-description-images-navigation-slide-img-border: $product-description-images-navigation-slide-img-border !default;
$product-desktop-description-images-navigation-slide-current-img-border-color: $product-description-images-navigation-slide-current-img-border-color !default;
$product-desktop-description-images-navigation-slide-current-img-border-color-hover: $gray-300 !default;

.product-images {
  .vib-container {
    background: rgba(255, 255, 255, 1);

    .vib-content {
      .vib-image {
        max-height: 90vh;
      }
    }

    .vib-footer {
      display: none;
    }

    .vib-thumbnail-wrapper {
      bottom: 0;
      top: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .vib-thumbnail,
      .vib-thumbnail-active {
        width: 100px;
        height: 100px;
      }

      .vib-thumbnail-active {
        box-shadow: inset 0 0 0 3px #666;
      }
    }
  }

  .description-images {
    position: relative;
    z-index: 1;

    .images-carousel {
      @include fullwidth-mobile();
      margin-bottom: $product-description-images-carousel-gap;

      img {
        cursor: zoom-in;
      }
    }

    .images-navigation {
      margin-bottom: $product-description-images-navigation-gap;
      overflow: hidden;

      .slick-list {
        margin-left: $product-description-images-navigation-slide-gap * -0.5;
        margin-right: $product-description-images-navigation-slide-gap * -0.5;

        .slick-slide {
          padding-left: $product-description-images-navigation-slide-gap * 0.5;
          padding-right: $product-description-images-navigation-slide-gap * 0.5;

          img {
            padding-bottom: $product-description-images-navigation-slide-img-gap;
            border-bottom: $product-description-images-navigation-slide-img-border;

            cursor: pointer;
          }

          &.carousel-current {
            img {
              border-bottom-color: $product-description-images-navigation-slide-current-img-border-color;
            }
          }
        }
      }
    }

    .is-fallback-image {
      .bi {
        display: block;
        position: absolute;
        inset: 0;
        margin: auto;
        width: $product-description-images-img-fallback-size;
        height: $product-description-images-img-fallback-size;
        color: $product-description-images-img-fallback-color;
      }
    }

    &:hover {
      .images-carousel {
        .slick-arrow {
          opacity: 1;
        }
      }
    }
  }

  @include media-breakpoint-up($product-breakpoint) {
    .description-images {
      .images-carousel {
        margin-bottom: $product-desktop-description-images-carousel-gap;
      }

      .images-navigation {
        margin-bottom: $product-desktop-description-images-navigation-gap;

        .slick-list {
          margin-left: $product-desktop-description-images-navigation-slide-gap * -0.5;
          margin-right: $product-desktop-description-images-navigation-slide-gap * -0.5;

          .slick-slide {
            padding-left: $product-desktop-description-images-navigation-slide-gap * 0.5;
            padding-right: $product-desktop-description-images-navigation-slide-gap * 0.5;

            img {
              padding-bottom: $product-desktop-description-images-navigation-slide-img-gap;
              border-bottom: $product-desktop-description-images-navigation-slide-img-border;
            }

            &:hover {
              img {
                border-bottom-color: $product-desktop-description-images-navigation-slide-current-img-border-color-hover;
              }

              &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                inset: 0;
                opacity: 0.3;
                margin-left: $product-desktop-description-images-navigation-slide-gap * 0.5;
                margin-right: $product-desktop-description-images-navigation-slide-gap * 0.5;
                margin-bottom: $product-desktop-description-images-navigation-slide-img-gap;
                background-color: $product-desktop-description-images-navigation-slide-current-img-border-color-hover;
              }
            }

            &.carousel-current {
              img {
                border-bottom-color: $product-desktop-description-images-navigation-slide-current-img-border-color;
              }
            }
          }
        }
      }
    }
  }
}
