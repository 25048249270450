
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// base
$productcolors-title-gap: $spacer * 0.5 !default;
$productcolors-title-font-size: $font-size-sm !default;
$productcolors-title-color: $gray-700 !default;

$productcolors-list-item-gap: $spacer * 0.4375 !default;
$productcolors-list-item-size: 2.5em !default;
$productcolors-list-item-border: $border-width solid rgba($gray-700, 0.15) !default;
$productcolors-list-item-border-radius: 50% !default;
$productcolors-list-item-icon-color: $gray-700 !default;

$productcolors-list-item-active-border-offset: $productcolors-list-item-size * -0.15 !default;
$productcolors-list-item-active-border-width: nth(map-get($borders, 'bold'), 1) !default;
$productcolors-list-item-active-border-color: $black !default;

$productcolors-list-item-hover-border-color: rgba($productcolors-list-item-active-border-color, 0.1) !default;

// Desktop
$product-breakpoint: $desktop-breakpoint !default;

// compact
$productcolors-compact-list-item-gap: $spacer * 0.3125 !default;
$productcolors-compact-list-item-size: 1.625em !default;
$productcolors-compact-list-item-border: $productcolors-list-item-border !default;
$productcolors-compact-list-item-border-radius: $productcolors-list-item-border-radius !default;
$productcolors-compact-list-item-icon-color: $productcolors-list-item-icon-color !default;

$productcolors-compact-list-item-active-border-offset: $productcolors-list-item-active-border-width * -1.5 !default;
$productcolors-compact-list-item-active-border-width: $productcolors-list-item-active-border-width !default;
$productcolors-compact-list-item-active-border-color: $gray-700 !default;

$productcolors-compact-list-item-hover-border-color: rgba($productcolors-compact-list-item-active-border-color, 0.5) !default;

// extended
$productcolors-extended-list-item-gap: 2.5% !default;
$productcolors-extended-list-item-size: 17.5% !default;
$productcolors-extended-list-item-border: $productcolors-list-item-border !default;
$productcolors-extended-list-item-border-radius: $border-radius !default;
$productcolors-extended-list-item-icon-color: $productcolors-list-item-icon-color !default;
$productcolors-extended-list-item-text-font-size: 12px !default;

$productcolors-extended-list-large-item-size: 30.833% !default;

$productcolors-extended-list-item-active-border-offset: $productcolors-list-item-active-border-width * -1 !default;
$productcolors-extended-list-item-active-border-width: $productcolors-list-item-active-border-width !default;
$productcolors-extended-list-item-active-border-color: $black !default;

$productcolors-extended-list-item-hover-border-color: rgba($productcolors-extended-list-item-active-border-color, 0.5) !default;

.product-colors {
  width: 100%;

  .colors-title {
    margin-bottom: $productcolors-title-gap;
    font-size: $productcolors-title-font-size;
    color: $productcolors-title-color;
  }

  .colors-list {
    @include list-unstyled();
    display: flex;
    flex-wrap: wrap;
    margin: $productcolors-list-item-gap * -0.5;

    > li {
      padding: $productcolors-list-item-gap * 0.5;
      width: calc($productcolors-list-item-size - $productcolors-list-item-gap);

      .list-item {
        cursor: pointer;

        .color {
          display: block;
          position: relative;
          margin: auto;
          padding-top: calc(100% - (nth($productcolors-list-item-border, 1) * 2));
          border: $productcolors-list-item-border;
          border-radius: $productcolors-list-item-border-radius;
          color: inherit;
          text-decoration: none;
          background-size: cover;
          background-position: center;

          &.inverted-foreground-color {
            color: $white;
          }

          &:before {
            display: block;
            content: '';
            position: absolute;
            inset: $productcolors-list-item-active-border-offset;
            border: $productcolors-list-item-active-border-width solid transparent;
            border-radius: inherit;
          }

          .bi {
            display: block;
            margin: calc(-1px + (nth($productcolors-list-item-border, 1) * -1));
            width: calc(2px + 100% + (nth($productcolors-list-item-border, 1) * 2));
            height: calc(2px + 100% + (nth($productcolors-list-item-border, 1) * 2));
            color: $productcolors-list-item-icon-color;
          }
        }

        &:hover {
          .color {
            &:before {
              border-color: $productcolors-list-item-hover-border-color;
            }
          }
        }
      }

      &.active {
        .list-item {
          .color {
            box-shadow: none;

            &:before {
              border-color: $productcolors-list-item-active-border-color;
            }
          }
        }
      }

      &.overlength {
        .list-item {
          .color {
            padding-top: 0;
            border-color: transparent;
          }
        }
      }
    }
  }

  &.is-compact {
    .colors-list {
      margin: $productcolors-compact-list-item-gap * -0.5;

      > li {
        padding: $productcolors-compact-list-item-gap * 0.5;
        width: calc($productcolors-compact-list-item-size - $productcolors-compact-list-item-gap);

        .list-item {
          .color {
            padding-top: calc(100% - (nth($productcolors-compact-list-item-border, 1) * 2));
            border: $productcolors-compact-list-item-border;
            border-radius: $productcolors-compact-list-item-border-radius;

            &:before {
              inset: $productcolors-compact-list-item-active-border-offset;
              border-width: $productcolors-compact-list-item-active-border-width;
            }

            .bi {
              margin: calc(-1px + (nth($productcolors-compact-list-item-border, 1) * -1));
              width: calc(2px + 100% + (nth($productcolors-compact-list-item-border, 1) * 2));
              height: calc(2px + 100% + (nth($productcolors-compact-list-item-border, 1) * 2));
              color: $productcolors-compact-list-item-icon-color;
            }

            .check-icon {
              display: none;
            }
          }

          &:hover {
            .color {
              &:before {
                border-color: $productcolors-compact-list-item-hover-border-color;
              }
            }
          }
        }

        &.active {
          .list-item {
            .color {
              &:before {
                border-color: $productcolors-compact-list-item-active-border-color;
              }
            }
          }
        }

        &.overlength {
          .list-item {
            .color {
              padding-top: 0;
              border-color: transparent;
            }
          }
        }
      }
    }
  }

  &.is-extended {
    .colors-list {
      margin: $productcolors-extended-list-item-gap * -0.5;

      > li {
        padding: $productcolors-extended-list-item-gap * 0.5;
        width: calc($productcolors-extended-list-item-gap + $productcolors-extended-list-item-size);

        &.large-item {
          width: calc($productcolors-extended-list-item-gap + $productcolors-extended-list-large-item-size);
        }

        .list-item {
          .color {
            padding-top: calc(100% - (nth($productcolors-extended-list-item-border, 1) * 2));
            border: $productcolors-extended-list-item-border;
            border-radius: $productcolors-extended-list-item-border-radius;

            &:before {
              inset: $productcolors-extended-list-item-active-border-offset;
              border-width: $productcolors-extended-list-item-active-border-width;
              border-radius: $productcolors-extended-list-item-border-radius * 1.5;
            }

            .bi {
              margin: calc(-1px + (nth($productcolors-extended-list-item-border, 1) * -1));
              width: calc(2px + 100% + (nth($productcolors-extended-list-item-border, 1) * 2));
              height: calc(2px + 100% + (nth($productcolors-extended-list-item-border, 1) * 2));
              color: $productcolors-extended-list-item-icon-color;
            }

            .check-icon {
              color: inherit;
              position: absolute;
              bottom: 5px;
              right: 5px;
              width: auto;
              height: auto;
              font-size: 125%;
            }
          }

          .color-name {
            display: block;
            width: 100%;
            font-size: $productcolors-extended-list-item-text-font-size;
            text-align: center;
            line-height: 1.1;
          }

          &:hover {
            .color {
              &:before {
                border-color: $productcolors-extended-list-item-hover-border-color;
              }
            }
          }
        }

        &.active {
          .list-item {
            .color {
              &:before {
                border-color: $productcolors-extended-list-item-active-border-color;
              }
            }
          }
        }

        &.overlength {
          .list-item {
            .color {
              padding-top: 0;
              border-color: transparent;
            }
          }
        }
      }
    }
  }
}
