
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// desktop
$product-breakpoint: $desktop-breakpoint !default;

.product-wrapper {
  position: relative;

  .variant-loader {
    > div + div {
      margin: 0 auto;
      padding-top: 75vh;
      width: 0;

      @include media-breakpoint-up($product-breakpoint) {
        padding-top: 30vh;
      }
    }
  }
}
