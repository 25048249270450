
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$productwishlistbutton-link-color: $gray-400 !default;

.product-wishlistbutton {
  .wishlistbutton {
    display: block;
    width: 100%;

    &.btn-link {
      padding: 0;
      color: $productwishlistbutton-link-color;
    }
  }
}
