
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$mobile-productitem-grid-columns: 100% !default;
$productitem-grid-gap: $spacer * 0.75 !default;
$productitem-gutter: $grid-gutter-width * 0.5 !default;
$productitem-padding-y: $spacer * 0.5 !default;
$productitem-padding-x: 0 !default;
$productitem-border: map-get($borders, 'base') !default;

$productitem-image-width: 90px !default;
$productitem-img-fallback-size: 100% !default;
$productitem-img-fallback-color: $imgfallback !default;

.product-item {
  display: grid;
  grid-gap: $productitem-grid-gap;
  grid-template-columns: $mobile-productitem-grid-columns;
  padding: $productitem-padding-y $productitem-padding-x;
  border-top: $productitem-border;
  border-bottom: $productitem-border;

  .link-unstyled, .link-unstyled:link, .link-unstyled:hover {
    color: inherit;
    text-decoration: inherit;
  }

  > .product-image {
    grid-column: 1;
    width: $productitem-image-width;

    img,
    .is-fallback-image {
      width: 100%;

      .bi {
        width: $productitem-img-fallback-size;
        height: $productitem-img-fallback-size;
        color: $productitem-img-fallback-color;
      }
    }
  }

  > .product-designation {
    grid-column: 1;

    .product-name {
      width: 100%;
    }

    .product-informations {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > * {
        padding-left: $productitem-grid-gap;
        padding-right: $productitem-grid-gap;
        width: max-content;

        &:first-child { padding-left: 0; }
        &:last-child { padding-right: 0; }
      }
    }
  }

  > .product-actions {
    grid-column: 1;
  }

  > .product-addons {
    grid-column: 1;
    font-size: .85rem;

    .addon-title {
    }

    .product-item {
      .product-image {
          .is-fallback-image {
            background-color: #fff;
          }
      }
    }
  }

  + .product-item {
    border-top: 0 none;
  }

  &:first-child {
    border-top: 0 none;
  }
  &:last-child {
    border-bottom: 0 none;
  }

  &.blank {
    padding: 0;
    border: 0 none;
  }

  &.inline {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-up($desktop-breakpoint) {
    grid-template-columns: [start] min-content 1fr min-content [end];

    > .product-image {
      width: $productitem-image-width;
    }

    > .product-designation {
      grid-column: 2;
    }

    > .product-actions {
      grid-column: 3;
      width: 87px;
    }

    > .product-addons {
      grid-row: 2;
      grid-column: 2 / span 2;
      width: 100%;

      .product-item {
        padding: 0;

        + .product-item {
          padding-top: $productitem-padding-y;
        }
      }
    }
  }
}
