
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$productcolors-title-gap: $spacer * 0.5 !default;
$productcolors-title-font-size: $font-size-sm !default;
$productcolors-title-color: $gray-700 !default;

$productcolors-dropdown-icon-gap: $spacer !default;

.product-dropdown {
  .dropdown-title {
    margin-bottom: $productcolors-title-gap;
    font-size: $productcolors-title-font-size;
    color: $productcolors-title-color;
  }

  .dropdown {
    .dropdown-icon {
      margin-left: $productcolors-dropdown-icon-gap;
    }
  }
}
