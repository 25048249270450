
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.modal {
  .modal-header {
    .close {
      margin-left: 0;
      font-size: inherit;
    }
  }
}
