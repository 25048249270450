
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$productbuttongroup-title-gap: $spacer * 0.5 !default;
$productbuttongroup-title-font-size: $font-size-sm !default;
$productbuttongroup-title-color: $gray-700 !default;

// desktop
$product-breakpoint: $desktop-breakpoint !default;

.product-buttongroup {
  width: 100%;

  .buttongroup-title {
    margin-bottom: $productbuttongroup-title-gap;
    font-size: $productbuttongroup-title-font-size;
    color: $productbuttongroup-title-color;
  }

  .buttongroup-group {
    margin-bottom: $btn-border-width;
    margin-right: $btn-border-width;
    width: 100%;
    flex-wrap: wrap;

    .btn {
      width:100%;
      position: relative;
      margin-left: 0;
      margin-bottom: $btn-border-width * -1;
      margin-right: $btn-border-width * -1;
      padding-left:40px;
      padding-right:40px;
    }

    .button-container{
      width: 100%;
    }

    .check-icon{
      position: absolute;
      right:10px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    &.btn-group-vertical {
      margin-bottom: 0;
      width: 100%;

      .button-container{
        width: 100%;

        .btn {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-up($product-breakpoint) {
    .buttongroup-group {
      .button-container{
        width: 50%;
        flex-wrap: wrap;
      }
    }
  }
}
