
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$productdata-gap: $component-padding-y !default;
$productdata-productdata-gap: 2px !default;
$productdata-padding-y: $grid-gutter-width * 0.4 !default;
$productdata-padding-x: $productdata-padding-y !default;
$productdata-bg: $beige !default;
$productdata-border-radius: $border-radius-sm !default;

$productdata-link-font-weight: $font-weight-medium !default;

$productdata-content-gap: $spacer !default;
$productdata-content-box-shadow-spread: $spacer * 1.5 !default;
$productdata-content-box-shadow: inset 0 ($productdata-content-box-shadow-spread * -0.75) $productdata-content-box-shadow-spread ($productdata-content-box-shadow-spread * 0.25) $productdata-bg !default;
$productdata-content-transition-in: all ease-out 300ms !default;
$productdata-content-transition-out: all ease-out 200ms !default;
$productdata-content-expander-gap: $spacer * 0.5 !default;

$productdata-content-descriptionlist-padding-y: $spacer * 0.3 !default;
$productdata-content-descriptionlist-padding-x: $form-grid-gutter-width * 0.5 !default;
$productdata-content-descriptionlist-border: $border-width solid darken($productdata-bg, 8%) !default;
$productdata-content-descriptionlist-title-width: 40% !default;
$productdata-content-descriptionlist-title-color: $dark !default;
$productdata-content-descriptionlist-data-width: 60% !default;

// desktop
$productdata-breakpoint: $desktop-breakpoint !default;

$productdata-desktop-gap: $component-desktop-padding-y !default;
$productdata-desktop-productdata-gap: $productdata-productdata-gap !default;
$productdata-desktop-padding-y: $component-padding-y !default;
$productdata-desktop-padding-x: $component-padding-x !default;
$productdata-desktop-border-radius: $productdata-border-radius !default;

$productdata-destkop-content-gap: 0 !default;
$productdata-desktop-content-descriptionlist-title-width: 25% !default;
$productdata-desktop-content-descriptionlist-data-width: 75% !default;

.productdata {
  @include fullwidth-mobile();
  position: relative;
  margin-bottom: $productdata-productdata-gap;
  padding: $productdata-padding-y $productdata-padding-x;
  background-color: $productdata-bg;

  &:first-child {
    margin-top: $productdata-gap;
    border-top-left-radius: $productdata-border-radius;
    border-top-right-radius: $productdata-border-radius;
  }

  &:last-child {
    margin-bottom: $productdata-gap;
    border-bottom-left-radius: $productdata-border-radius;
    border-bottom-right-radius: $productdata-border-radius;
  }

  a {
    font-weight: $productdata-link-font-weight;
  }

  .productdata-title {
    text-align: left;
    white-space: normal;

    > * {
      margin: 0;
      padding: 0;
      width: auto;
      height: auto;
      font-size: inherit;
      font-weight: inherit;
      font-style: inherit;
      color: inherit;
      line-height: inherit;
    }
  }

  .productdata-content {
    margin-top: $productdata-content-gap;
    margin-bottom: $productdata-content-gap;

    dl {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin-left: $productdata-content-descriptionlist-padding-x * -1;
      margin-right: $productdata-content-descriptionlist-padding-x * -1;

      > dt,
      > dd {
        padding: $productdata-content-descriptionlist-padding-y $productdata-content-descriptionlist-padding-x;
      }

      > dt {
        width: $productdata-content-descriptionlist-title-width;
        color: $productdata-content-descriptionlist-title-color;
      }

      > dd {
        width: $productdata-content-descriptionlist-data-width;
      }

      &:not(.bordered) {
        > dt,
        > dd {
          &:first-child,
          &:nth-child(2) {
            padding-top: 0;
          }

          &:last-child,
          &:nth-last-child(2) {
            padding-bottom: 0;
          }
        }
      }

      &.bordered {
        border-top: $productdata-content-descriptionlist-border;

        > dt,
        > dd {
          border-bottom: $productdata-content-descriptionlist-border;
        }

        > dd {
          margin-bottom: 0;
        }
      }
    }
  }

  &.is-expandable {
    .productdata-content {
      position: relative;
      overflow: hidden;

      &:before {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: $productdata-content-box-shadow-spread * -2;
        right: $productdata-content-box-shadow-spread * -2;
        height: 200%;
        box-shadow: $productdata-content-box-shadow;
        transition: inherit;
      }

      &.expand-enter-active {
        transition: $productdata-content-transition-in;
      }

      &.expand-leave-active {
        transition: $productdata-content-transition-out;
      }

      &.expand-enter,
      &.expand-leave-to {
        &:before {
          transform: translate3d(0, $productdata-content-box-shadow-spread * 0, 0);
        }
      }

      &.expand-enter-to,
      &.expand-leave {
        &:before {
          transform: translate3d(0, $productdata-content-box-shadow-spread * 2, 0);
        }
      }

      &.expand-state-open {
        &:before {
          display: none;
        }
      }

      &.is-underflowing {
        &:before {
          display: none;
        }

        + .productdata-expander {
          display: none;
        }
      }
    }

    .productdata-expander {
      display: block;
      margin-top: $productdata-content-expander-gap;
      text-decoration: none;
    }

    &.is-expanded {
      .productdata-expander {
        .bi {
          transform: scaleY(-1);
        }
      }
    }
  }

  @include media-breakpoint-up($productdata-breakpoint) {
    margin-bottom: $productdata-desktop-productdata-gap;
    padding: $productdata-desktop-padding-y $productdata-desktop-padding-x;

    &.is-expandable {
      min-height: 196px;
    }

    &:first-child {
      margin-top: $productdata-desktop-gap;
      border-top-left-radius: $productdata-desktop-border-radius;
      border-top-right-radius: $productdata-desktop-border-radius;
    }

    &:last-child {
      margin-bottom: $productdata-desktop-gap;
      border-bottom-left-radius: $productdata-desktop-border-radius;
      border-bottom-right-radius: $productdata-desktop-border-radius;
    }

    .productdata-title {}

    .productdata-content {
      margin-top: $productdata-destkop-content-gap;
      margin-bottom: $productdata-destkop-content-gap;

      dl {
        > dt {
          width: $productdata-desktop-content-descriptionlist-title-width;
        }

        > dd {
          width: $productdata-desktop-content-descriptionlist-data-width;
        }
      }
    }
  }
}
