
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$productprice-active-font-size: 1.4em !default;
$productprice-active-font-weight: $font-weight-medium !default;
$productprice-active-color: $gray-700 !default;

$productprice-active-currency-font-size: 0.777777em !default;
$productprice-active-fraction-font-size: inherit !default;

$productprice-active-promotional-color: $red !default;

$productprice-original-gap: $spacer * 0.5 !default;
$productprice-original-font-size: $productprice-active-font-size !default;
$productprice-original-font-weight: $productprice-active-font-weight !default;
$productprice-original-color: $productprice-active-color !default;
$productprice-original-linethrouth: set-nth(map-get($borders, 'base'), 3, rgba($productprice-original-color, 0.85)) !default;
$productprice-original-linethrouth-glow: 0 1px 2px 0 rgba($productprice-original-color, 0.85) !default;

$productprice-original-currency-font-size: $productprice-active-currency-font-size !default;
$productprice-original-fraction-font-size: $productprice-active-fraction-font-size !default;

$productprice-vatinfo-font-size: $font-size-sm !default;
$productprice-vatinfo-color: $gray-600 !default;

$productprice-sum-font-weight: $font-weight-bold !default;

.product-price {
  .price-active {
    display: block;
    font-size: $productprice-active-font-size;
    font-weight: $productprice-active-font-weight;
    color: $productprice-active-color;

    .currency {
      font-size: $productprice-active-currency-font-size;
    }

    .integer {}

    .group {}

    .decimal {}

    .fraction {
      font-size: $productprice-active-fraction-font-size;
    }

    &.is-promotional {
      color: $productprice-active-promotional-color;
    }
  }

  .price-original {
    display: block;
    position: relative;
    margin-left: $productprice-original-gap;
    font-size: $productprice-original-font-size;
    font-weight: $productprice-original-font-weight;
    color: $productprice-original-color;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 52.5%;
      left: 0;
      right: 0;
      border-bottom: $productprice-original-linethrouth;
      box-shadow: $productprice-original-linethrouth-glow;
    }

    .currency {
      font-size: $productprice-original-currency-font-size;
    }

    .integer {}

    .group {}

    .decimal {}

    .fraction {
      font-size: $productprice-original-fraction-font-size;
    }
  }

  .hide-fraction {

    .decimal,
    .fraction {
      display: none;
    }
  }

  .price-vatinfo {
    font-size: $productprice-vatinfo-font-size;
    color: $productprice-vatinfo-color;
    text-align: right;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &.inherit {

    .price-active,
    .price-original {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }

    .price-active {
      &.is-promotional {
        color: $productprice-active-promotional-color;
      }
    }
  }

  &.is-sum {

    .price-active,
    .price-original {
      font-weight: $productprice-sum-font-weight;
    }
  }
}
