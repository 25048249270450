
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$productshoppingcartbutton-padding-y: $btn-padding-y * 0.4 !default;
$productshoppingcartbutton-padding-x: $productshoppingcartbutton-padding-y !default;
$productshoppingcartbutton-bg: $primary !default;
$productshoppingcartbutton-border: transparent !default;
$productshoppingcartbutton-font-weight: $font-weight-bold !default;
$productshoppingcartbutton-line-height: 1.1 !default;
$productshoppingcartbutton-hover-bg: darken($productshoppingcartbutton-bg, 5%) !default;
$productshoppingcartbutton-hover-border: darken($productshoppingcartbutton-border, 5%) !default;

$productshoppingcartbutton-quantity-width: $spacer * 4 !default;
$productshoppingcartbutton-quantity-height: $input-height-sm !default;

$productshoppingcartbutton-quantity-toggle-padding-y: 0 !default;
$productshoppingcartbutton-quantity-toggle-padding-x: $btn-padding-x * 0.5 !default;
$productshoppingcartbutton-quantity-toggle-bg: darken($productshoppingcartbutton-bg, 2.5%) !default;
$productshoppingcartbutton-quantity-toggle-border: darken($productshoppingcartbutton-border, 2.5%) !default;
$productshoppingcartbutton-quantity-toggle-hover-bg: darken($productshoppingcartbutton-quantity-toggle-bg, 6%) !default;
$productshoppingcartbutton-quantity-toggle-hover-border: darken($productshoppingcartbutton-quantity-toggle-border, 6%) !default;

$productshoppingcartbutton-quantity-menu-bg: $productshoppingcartbutton-bg !default;
$productshoppingcartbutton-quantity-menu-border: $productshoppingcartbutton-border !default;
$productshoppingcartbutton-quantity-menu-caret-size: $spacer * 0.6 !default;
$productshoppingcartbutton-quantity-menu-item-padding-y: $dropdown-item-padding-y !default;
$productshoppingcartbutton-quantity-menu-item-padding-x: $dropdown-item-padding-x * 0.6 !default;
$productshoppingcartbutton-quantity-menu-item-hover-bg: $productshoppingcartbutton-hover-bg !default;

$productshoppingcartbutton-quantity-control-padding-y: $productshoppingcartbutton-quantity-toggle-padding-y !default;
$productshoppingcartbutton-quantity-control-padding-x: $productshoppingcartbutton-quantity-toggle-padding-x !default;
$productshoppingcartbutton-quantity-control-bg: $productshoppingcartbutton-quantity-toggle-bg !default;
$productshoppingcartbutton-quantity-control-border: $productshoppingcartbutton-quantity-toggle-bg !default;
$productshoppingcartbutton-quantity-control-hover-bg: $productshoppingcartbutton-quantity-toggle-hover-bg !default;
$productshoppingcartbutton-quantity-control-hover-border: $productshoppingcartbutton-quantity-toggle-hover-border !default;
$productshoppingcartbutton-quantity-control-focus-bg: $productshoppingcartbutton-quantity-toggle-hover-bg !default;
$productshoppingcartbutton-quantity-control-focus-border: $white !default;

$productshoppingcartbutton-text-padding-y: 0 !default;
$productshoppingcartbutton-text-padding-x: $spacer !default;

.product-shoppingcartbutton {
  .shoppingcartbutton {
    @include button-variant($productshoppingcartbutton-bg, $productshoppingcartbutton-border, $productshoppingcartbutton-hover-bg, $productshoppingcartbutton-hover-border);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $productshoppingcartbutton-padding-y $productshoppingcartbutton-padding-x;
    width: 100%;
    font-weight: $productshoppingcartbutton-font-weight;
    line-height: $productshoppingcartbutton-line-height;

    .shoppingcartbutton-quantity {
      width: $productshoppingcartbutton-quantity-width;
      height: $productshoppingcartbutton-quantity-height;

      .dropdown {
        width: 100%;
        height: 100%;

        .dropdown-toggle {
          @include button-variant($productshoppingcartbutton-quantity-toggle-bg, $productshoppingcartbutton-quantity-toggle-border, $productshoppingcartbutton-quantity-toggle-hover-bg, $productshoppingcartbutton-quantity-toggle-hover-border);
          padding-top: $productshoppingcartbutton-quantity-toggle-padding-y;
          padding-bottom: $productshoppingcartbutton-quantity-toggle-padding-y;
          padding-left: $productshoppingcartbutton-quantity-toggle-padding-x;
          height: 100%;
          background-image: escape-svg(str-replace($dropdown-toggle-icon, "fill='#{$dropdown-toggle-color}'", "fill='#{color-yiq($productshoppingcartbutton-quantity-toggle-bg)}'"));
          box-shadow: none!important;
        }

        .dropdown-menu {
          margin-bottom: 0;
          margin-left: 50%;
          min-width: 0;
          background-color: $productshoppingcartbutton-quantity-menu-bg;
          border-color: $productshoppingcartbutton-quantity-menu-border;
          color: color-yiq($productshoppingcartbutton-quantity-menu-bg);
          transform: translate3d(-50%, calc(-100% - ($productshoppingcartbutton-padding-y + $productshoppingcartbutton-quantity-menu-caret-size + 2px)), 0)!important;

          &:after {
            display: block;
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            border-width: $productshoppingcartbutton-quantity-menu-caret-size $productshoppingcartbutton-quantity-menu-caret-size 0;
            border-style: solid solid none;
            border-color: $productshoppingcartbutton-quantity-menu-bg transparent transparent;
            transform: translate3d(-50%, 0, 0);
          }

          .dropdown-item {
            padding: $productshoppingcartbutton-quantity-menu-item-padding-y $productshoppingcartbutton-quantity-menu-item-padding-x;
            color: inherit;

            &:hover {
              background-color: $productshoppingcartbutton-quantity-menu-item-hover-bg;
            }
          }
        }
      }

      .form-control {
        padding: $productshoppingcartbutton-quantity-control-padding-y $productshoppingcartbutton-quantity-control-padding-x;
        height: 100%;
        background-color: $productshoppingcartbutton-quantity-control-bg;
        border-color: $productshoppingcartbutton-quantity-control-border;
        border-radius: $dropdown-border-radius;
        color: color-yiq($productshoppingcartbutton-quantity-control-bg);
        text-align: center;
        transition: $transition-base;

        &:focus {
          background-color: $productshoppingcartbutton-quantity-control-focus-bg!important;
          border-color: $productshoppingcartbutton-quantity-control-focus-border!important;
        }
      }
    }

    .shoppingcartbutton-text {
      padding: $productshoppingcartbutton-text-padding-y $productshoppingcartbutton-text-padding-x;
    }

    &:hover {
      .shoppingcartbutton-quantity {
        .dropdown {
          .dropdown-toggle {
            color: color-yiq($productshoppingcartbutton-quantity-toggle-hover-bg);
            @include gradient-bg($productshoppingcartbutton-quantity-toggle-hover-bg);
            border-color: $productshoppingcartbutton-quantity-toggle-hover-border;
          }
        }

        .form-control {
          background-color: $productshoppingcartbutton-quantity-control-hover-bg;
          border-color: $productshoppingcartbutton-quantity-control-hover-bg;
        }
      }
    }
  }

  &.small{
    .shoppingcartbutton{
      .shoppingcartbutton-quantity{
        height: auto;
        width: auto;
        .dropdown {
          .dropdown-toggle{
            padding: 0 1.2rem 0 0.8rem;
            background-position: center right;
          }
        }
      }
      .shoppingcartbutton-text{
        padding: 0 0.5rem;
      }
    }
  }
}
