
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$productdataheader-gap: $component-padding-y !default;
$productdataheader-productdataheader-gap: 2px !default;
$productdataheader-padding-y: $grid-gutter-width * 0.5 !default;
$productdataheader-padding-x: $productdataheader-padding-y !default;
$productdataheader-bg: $beige !default;
$productdataheader-border-radius: $border-radius-sm !default;

$productdataheader-title-gap: $spacer !default;

$productdataheader-breakpoint: $desktop-breakpoint !default;

$productdataheader-desktop-gap: $component-desktop-padding-y !default;
$productdataheader-desktop-productdataheader-gap: $productdataheader-productdataheader-gap !default;
$productdataheader-desktop-padding-y: $component-padding-y !default;
$productdataheader-desktop-padding-x: $component-padding-x !default;
$productdataheader-desktop-border-radius: $productdataheader-border-radius !default;

$productdataheader-desktop-title-gap: 0 !default;

.productdata-header {
  @include fullwidth-mobile();
  position: relative;
  margin-bottom: $productdataheader-productdataheader-gap;
  padding: $productdataheader-padding-y $productdataheader-padding-x;
  background-color: $productdataheader-bg;

  &:first-child {
    margin-top: $productdataheader-gap;
    border-top-left-radius: $productdataheader-border-radius;
    border-top-right-radius: $productdataheader-border-radius;
  }

  &:last-child {
    margin-bottom: $productdataheader-gap;
    border-bottom-left-radius: $productdataheader-border-radius;
    border-bottom-right-radius: $productdataheader-border-radius;
  }

  .productdata-header-title {
    margin-bottom: $productdataheader-title-gap;
    text-align: left;
    white-space: normal;

    > * {
      margin-bottom: 0;
    }
  }

  .productdata-header-content {}

  @include media-breakpoint-up($productdataheader-breakpoint) {
    margin-bottom: $productdataheader-desktop-productdataheader-gap;
    padding: $productdataheader-desktop-padding-y $productdataheader-desktop-padding-x;

    &:first-child {
      margin-top: $productdataheader-desktop-gap;
      border-top-left-radius: $productdataheader-desktop-border-radius;
      border-top-right-radius: $productdataheader-desktop-border-radius;
    }

    &:last-child {
      margin-bottom: $productdataheader-desktop-gap;
      border-bottom-left-radius: $productdataheader-desktop-border-radius;
      border-bottom-right-radius: $productdataheader-desktop-border-radius;
    }

    .productdata-header-title {
      margin-bottom: $productdataheader-desktop-title-gap;
    }

    .productdata-header-content {}
  }
}
