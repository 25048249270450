
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$productcharacteristics-item-gap-y: 0 !default;
$productcharacteristics-item-gap-x: $spacer * 1.2 !default;

.product-characteristics {
  display: flex;
  flex-wrap: wrap;
  margin: ($productcharacteristics-item-gap-y * -0.5) ($productcharacteristics-item-gap-x * -0.5);

  > span {
    display: block;
    padding: ($productcharacteristics-item-gap-y * 0.5) ($productcharacteristics-item-gap-x * 0.5);
  }
}
